import React, { Component } from "react"
import { FormControl } from "baseui/form-control"
import { Search } from "baseui/icon"
import { Input } from "baseui/input"
import GoogleMapReact from "google-map-react"
import Marker from "../../assets/Marker.png"

const PinMarker = () => <img alt={"marker"} src={Marker} width={30} />

class MapWithSearchBox extends Component {
  constructor(props) {
    super(props)
    this.searchInput = React.createRef()
    this.state = {
      lat: props.lat,
      lng: props.long,
    }
  }

  apiLoaded = (map, maps) => {
    if (maps) {
      this.searchBox = new maps.places.Autocomplete(this.searchInput.current, {
        componentRestrictions: { country: ["th"] },
      })
      this.searchBox.addListener("place_changed", () => {
        this.onPlacesChanged()
      })
    }
  }

  onPlacesChanged = () => {
    const place = this.searchBox.getPlace()
    if (place && place.geometry) {
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.setState({
        lat,
        lng,
      })
      this.props.handleChange({
        address: `${place.name} ${place.formatted_address}`,
        lat,
        lng,
      })
    }
  }

  render() {
    const { lat, lng } = this.state
    const { error } = this.props
    return (
      <React.Fragment>
        <FormControl
          label="ค้นหา | Search"
          overrides={{
            ControlContainer: {
              style: {
                marginBottom: 0,
                position: "relative",
              },
            },
            Caption: {
              style: () => {
                return {
                  position: "absolute",
                  top: "10%",
                  right: "8px",
                }
              },
            },
          }}
          error={error}
        >
          <Input
            inputRef={this.searchInput}
            overrides={{
              Before: () => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  <Search size="18px" />
                </div>
              ),
            }}
            error={error}
            name={"address"}
          />
        </FormControl>
        <div style={{ height: "100%", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.GOOGLE_MAP_API_KEY,
              libraries: ["places"],
            }}
            center={{
              lat,
              lng,
            }}
            zoomControl={false}
            zoom={17}
            onGoogleApiLoaded={({ map, maps }) => {
              this.apiLoaded(map, maps)
            }}
          >
            <PinMarker lat={lat} lng={lng} />
          </GoogleMapReact>
        </div>
      </React.Fragment>
    )
  }
}

export default MapWithSearchBox
