import React, { useState, useEffect } from 'react'
import { Block } from 'baseui/block'
import { Input } from 'baseui/input'
import {
  ModalBody,
  Modal,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import cloudApi from 'cloudApi/api'
import { Search } from 'baseui/icon'
import withWorkplace from 'hoc/withWorkplace'
import { H5, Paragraph4 } from 'baseui/typography'
import { Edit16, Map16, TrashCan16 } from '@carbon/icons-react'
import { Button } from 'baseui/button'
import { Plus } from 'baseui/icon'
import WorkplaceModal from 'components/WorkPlaceModal'
import ErrorModal from 'components/ErrorModal'

import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from 'baseui/table'
import { Pagination } from 'baseui/pagination'
import withAdminNavigate from 'hoc/withAdminNavigate'
import ReportBox from 'components/ReportBox'

const WorkplacePage = ({ workPlaceState, workPlaceDispatch }) => {
  const {
    workplacesSummary,
    page,
    workPlaceInDashboard,
    limit,
    count,
  } = workPlaceState
  const fetchWorkplacesSummary = () => {
    cloudApi
      .getWorkplacesSummary()
      .then(data =>
        workPlaceDispatch({ type: 'FETCH_WORKPLACES_SUMMARY', payload: data })
      )
  }


  const fetchWorkplaces = () => {
    cloudApi.getWorkplacesInDashboardWithKeyword('', 0, limit).then(data => {
      workPlaceDispatch({
        type: 'FETCH_WORKPLACES_DASHBOARD_BY_KEYWORD',
        payload: { data: data.workplaces, reset: true, count: data.count },
      })
    })
    fetchWorkplacesSummary()
  }


  useEffect(() => {
    if (workPlaceInDashboard.length === 0) {
      fetchWorkplaces()
    }
  }, [workPlaceInDashboard])

  const [ showModal, setShowModal ] = useState(false)

  const [ keyword, setKeyword ] = React.useState(workPlaceState.keyword)

  const [ showError, setShowError ] = useState(false)
  const [ errorDetail, setErrorDetail ] = useState({ header: '', text: '' })

  const [ activeAddress, setActiveAddress ] = React.useState({
    address: null,
    lat: null,
    lng: null,
    id: null,
  })

  const [ needToDeleteItem, setNeedToDeleteItem ] = useState(null)

  const window = () => {
    const min = (page - 1) * limit
    return workPlaceInDashboard.slice(min, min + limit)
  }

  const handlePageChange = nextPage => {
    if (
      nextPage > page &&
      nextPage * limit > workPlaceInDashboard.length &&
      workPlaceInDashboard.length < count
    ) {
      cloudApi
        .getWorkplacesInDashboardWithKeyword(
          keyword,
          workPlaceInDashboard.length,
          nextPage * limit - workPlaceInDashboard.length
        )
        .then(data => {
          workPlaceDispatch({
            type: 'FETCH_WORKPLACES_DASHBOARD_BY_KEYWORD',
            payload: { data: data.workplaces, reset: false, count: data.count },
          })
        })
    }
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(count / limit)) {
      return
    }
    workPlaceDispatch({ type: 'UPDATE_WORKPLACES_PAGE', payload: nextPage })
  }
  return (
    <Block width="100%">
      <H5 marginTop="0px" marginBottom="10px">
        Workplace Management
      </H5>
      <Block display="flex" justifyContent="space-between">
        <ReportBox
          title={'ทั้งหมด'}
          value={(workplacesSummary && workplacesSummary.total) || 0}
          width={'28%'}
          type={'normal'}
        />
        <ReportBox
          title={'ในมหาลัย'}
          value={
            (workplacesSummary && workplacesSummary.inside_university) || 0
          }
          width={'28%'}
          type={'building'}
        />
        <ReportBox
          title={'นอกมหาลัย'}
          value={
            (workplacesSummary && workplacesSummary.outside_university) || 0
          }
          width={'28%'}
          type={'bus'}
        />
      </Block>
      <Block marginTop="20px" backgroundColor="white" padding="20px">
        <Block
          display="flex"
          marginBottom="25px"
          justifyContent="space-between"
        >
          <Input
            name="search"
            startEnhancer={<Search size="18px"/>}
            overrides={{
              Root: {
                style: {
                  width: '350px',
                },
              },
            }}
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                cloudApi
                  .getWorkplacesInDashboardWithKeyword(keyword, 0, limit)
                  .then(data =>
                    workPlaceDispatch({
                      type: 'FETCH_WORKPLACES_DASHBOARD_BY_KEYWORD',
                      payload: {
                        data: data.workplaces,
                        reset: true,
                        count: data.count,
                      },
                    })
                  )
                  .catch(() => {
                    setShowError(true)
                    setErrorDetail({
                      header: 'มีข้อผิดพลาด',
                      text: 'ไม่พบ workplace ที่ค้นหา',
                    })
                  })
                workPlaceDispatch({
                  type: 'UPDATE_WORKPLACES_PAGE',
                  payload: 1,
                })
              }
            }}
          />
          <Button
            onClick={() => setShowModal(true)}
            startEnhancer={() => <Plus size={24}/>}
          >
            เพิ่มใหม่
          </Button>
        </Block>
        <StyledTable>
          <StyledHead>
            <StyledHeadCell style={{ maxWidth: '55%' }}>
              ชื่อสถานที่
            </StyledHeadCell>
            <StyledHeadCell style={{ maxWidth: '12%' }}>ละติจูด</StyledHeadCell>
            <StyledHeadCell style={{ maxWidth: '12%' }}>
              ลองจิจูด
            </StyledHeadCell>
            <StyledHeadCell style={{ maxWidth: '21%' }}/>
          </StyledHead>
          {workPlaceInDashboard.length > 0 && (
            <StyledBody>
              {window().map((item, index) => (
                <StyledRow
                  style={{
                    paddingTop: 10,
                    PaddingBottom: 10,
                    backgroundColor:
                      (index + 1) % 2 === 0
                        ? 'rgba(247, 247, 247, 0.5)'
                        : 'transparent',
                  }}
                >
                  <StyledCell style={{ maxWidth: '55%' }}>
                    {item.thai_name}
                  </StyledCell>
                  <StyledCell style={{ maxWidth: '12%' }}>
                    {item.lat}
                  </StyledCell>
                  <StyledCell style={{ maxWidth: '12%' }}>
                    {item.lng}
                  </StyledCell>
                  <StyledCell style={{ maxWidth: '21%' }}>
                    <Block display="flex">
                      <Block
                        display="flex"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowModal(true)
                          setActiveAddress({
                            id: item.id,
                            lat: item.lat,
                            lng: item.lng,
                            address: item.thai_name,
                          })
                        }}
                      >
                        <Edit16 style={{ fill: '#ff7c1b' }}/>
                        <Paragraph4
                          marginTop="0px"
                          marginBottom="0px"
                          marginLeft="5px"
                          color="#ff7c1b"
                        >
                          แก้ไข
                        </Paragraph4>
                      </Block>

                      <Paragraph4
                        marginTop="0px"
                        marginBottom="0px"
                        marginLeft="5px"
                        marginRight="5px"
                      >
                        |
                      </Paragraph4>
                      <Block
                        display="flex"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setNeedToDeleteItem({
                            id: item.id,
                          })
                        }}
                      >
                        <TrashCan16 style={{ fill: '#ff7c1b' }}/>
                        <Paragraph4
                          marginTop="0px"
                          marginBottom="0px"
                          marginLeft="5px"
                          color="#ff7c1b"
                        >
                          ลบ
                        </Paragraph4>
                      </Block>
                      <Paragraph4
                        marginTop="0px"
                        marginBottom="0px"
                        marginLeft="5px"
                        marginRight="5px"
                      >
                        |
                      </Paragraph4>
                      <a
                        href={`https://www.google.com/maps/?q=${item.lat},${item.lng} `}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                      >
                        <Block style={{ cursor: 'pointer', display: 'flex' }}>
                          <Paragraph4
                            marginTop="0px"
                            marginBottom="0px"
                            marginRight="5px"
                            color="#ff7c1b"
                          >
                            ดูบนแผนที่
                          </Paragraph4>
                          <Map16 style={{ fill: '#ff7c1b' }}/>
                        </Block>
                      </a>
                    </Block>
                  </StyledCell>
                </StyledRow>
              ))}
            </StyledBody>
          )}
        </StyledTable>
        {workPlaceInDashboard.length > 0 && count && (
          <Block marginTop="25px" display="flex" justifyContent="center">
            <Pagination
              numPages={Math.ceil(count / limit)}
              currentPage={page}
              onPageChange={({ nextPage }) => handlePageChange(nextPage)}
            />
          </Block>
        )}
      </Block>
      <Modal
        onClose={() => setNeedToDeleteItem(null)}
        closeable
        isOpen={needToDeleteItem !== null}
        animate
      >
        <ModalHeader>แน่ใจหรือไม่?</ModalHeader>
        <ModalBody>
          คุณยืนยันในการลบข้อมูลนี้หรือไม่? การลบข้อมูลนี้จะไม่สามารถย้อนกลับได้
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={'minimal'}
            onClick={() => setNeedToDeleteItem(null)}
          >
            ยกเลิก
          </ModalButton>
          <ModalButton
            onClick={() => {
              cloudApi.deleteWorkPlace(needToDeleteItem.id).then(() => {
                fetchWorkplaces()
                fetchWorkplacesSummary()
                setNeedToDeleteItem(null)
              })
            }}
          >
            ยืนยัน
          </ModalButton>
        </ModalFooter>
      </Modal>
      <WorkplaceModal
        showModal={showModal}
        onClose={fetchData => {
          if (fetchData) {
            cloudApi
              .getWorkplacesInDashboardWithKeyword(keyword, 0, limit)
              .then(data => {
                workPlaceDispatch({
                  type: 'FETCH_WORKPLACES_DASHBOARD_BY_KEYWORD',
                  payload: {
                    data: data.workplaces,
                    reset: true,
                    count: data.count,
                  },
                })
                workPlaceDispatch({
                  type: 'UPDATE_WORKPLACES_PAGE',
                  payload: 1,
                })
                fetchWorkplacesSummary()
              })
          }
          setShowModal(false)
        }}
        activeAddress={activeAddress}
      />
      <ErrorModal
        open={showError}
        onClose={() => setShowError(false)}
        header={errorDetail.header}
        text={errorDetail.text}
      />
    </Block>
  )
}

export default withAdminNavigate(withWorkplace(WorkplacePage))
