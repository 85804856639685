import React, { useState, useEffect } from "react"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import cloudApi from "cloudApi/api"
import { Modal, ModalHeader, ModalBody, SIZE } from "baseui/modal"
import { Select } from "baseui/select"

import MapWithSearchBox from "components/MapWithSearchBox/MapWithSearchBox"
import { Button } from "baseui/button"

const kmitlLocation =  {
  lat: 13.7298717,
  lng: 100.7761224
}

const WorkPlaceModal = ({
  onClose,
  showModal,
  activeAddress = { address: null, lat: null, lng: null, id: null },
}) => {
  const [lat, setLat] = useState(kmitlLocation.lat)
  const [lng, setLng] = useState(kmitlLocation.lng)
  const [address, setAddress] = useState("")
  const [engAddress, setEngAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const [inUniversity, setInUniversity] = React.useState([
    { id: "true", label: "in university" },
  ])
  console.log(activeAddress)

  useEffect(() => {
    if (activeAddress.id) {
      setAddress(activeAddress.address)
    }
  }, [activeAddress])

  const resetAddress = () => {
    setAddress("")
    setLat(kmitlLocation.lat)
    setLng(kmitlLocation.lng)
    setEngAddress("")
    setInUniversity([{ id: "true", label: "in university" }])
  }

  const submitAddress = () => {
    setLoading(true)
    if (activeAddress.id) {
      return cloudApi
        .updateWorkPlace({
          id: activeAddress.id,
          thai_name: address,
          english_name: engAddress,
          lat,
          lng,
          inside_university: inUniversity[0].id === "true",
        })
        .then(() => {
          setLoading(false)
          onClose("fetchData")
          resetAddress()
        })
    }
    return cloudApi
      .createWorkPlace({
        thai_name: address,
        english_name: engAddress,
        lat,
        lng,
        inside_university: inUniversity[0].id === "true",
      })
      .then(() => {
        setLoading(false)
        onClose("fetchData")
        resetAddress()
      })
  }
  return (
    <Modal
      size={SIZE.full}
      onClose={() => {
        resetAddress()
        onClose()
      }}
      isOpen={showModal}
      overrides={{
        Root: {
          style: () => {
            return {
              zIndex: "auto",
            }
          },
        },
        Dialog: {
          style: {
            width: "800px",
            display: "flex",
            flexDirection: "column",
          },
        },
      }}
    >
      <ModalHeader>{activeAddress.id !== null ? 'แก้ไขข้อมูล Workplace' : 'เพิ่ม WorkPlace ใหม่'}</ModalHeader>
      <ModalBody>
        <Block display="flex">
          <Block width="500px" height="250px">
            <MapWithSearchBox
              error={""}
              handleChange={({ address, lat, lng }) => {
                setLat(lat)
                setLng(lng)
                setAddress(address)
              }}
              lat={lat}
              long={lng}
            />
          </Block>
          <Block width="300px" marginLeft="50px">
            <FormControl label="สถานที่ (TH)">
              <Input
                name="address"
                value={address}
                onChange={event => {
                  setAddress(event.target.value)
                }}
              />
            </FormControl>
            <FormControl label="Address (EN)">
              <Input
                name="engAddress"
                value={engAddress}
                onChange={event => {
                  setEngAddress(event.target.value)
                }}
              />
            </FormControl>
            <FormControl label="ละติจูด | Lat">
              <Input
                name="lat"
                value={lat}
                type="number"
                onChange={event => {
                  setLat(event.target.value)
                }}
              />
            </FormControl>
            <FormControl label="ลองจิจูด | Lng">
              <Input
                name="lng"
                value={lng}
                type="number"
                onChange={event => {
                  setLng(event.target.value)
                }}
              />
            </FormControl>
            <FormControl label="สถานที่ทำงาน | Place to work">
              <Select
                options={[
                  { id: "true", label: "ในมหาลัย | In Campus" },
                  { id: "false", label: "นอกมหาลัย | Outside Campus" },
                ]}
                onChange={({ value }) => setInUniversity(value)}
                value={inUniversity}
                overrides={{
                  Dropdown: {
                    style: { zIndex: 100, position: "relative" },
                  },
                }}
                clearable={false}
                searchable={false}
              />
            </FormControl>
          </Block>
        </Block>
        <Block display="flex" justifyContent="flex-end" marginTop="30px">
          <Button
            style={{ marginRight: "25px" }}
            onClick={() => {
              resetAddress()
              onClose()
            }}
            kind={"minimal"}
          >
            ยกเลิก
          </Button>
          <Button
            disabled={!lat || !lng || !address}
            onClick={() => submitAddress()}
            isLoading={loading}
          >
            ตกลง
          </Button>
        </Block>
      </ModalBody>
    </Modal>
  )
}

export default WorkPlaceModal
